import { number_to_price } from "helper/common";
import moment from "moment";
import React from "react";
import "./TradingMarket.scss";

export const MyTransaction = ({ betRecordsList }) => {
  return (
    <div className="left-list-container left-list-container2">
      <div className="body">
        <div className="top">
          <div className="top-description d-flex">
            <span>Thời gian</span>
            <span>Cặp</span>
            <span>Tổng</span>
            <span>Kết quả</span>
          </div>

          <div className="price-list x2">
            {betRecordsList && betRecordsList.length === 0 ? (
              <div style={{ color: "white" }} className="absolute-center">
                Không có dữ liệu
              </div>
            ) : (
              betRecordsList &&
              betRecordsList.map((item) => (
                <div
                  key={`MyTransaction-${item.betRecordUnit}-${
                    item.betRecordAmountIn
                  }-${item.updatedAt || item.createdAt}`}
                  className="price-list-item d-flex"
                >
                  <span
                    className={`${
                      item.betRecordType === "BetDown" ? "red" : "green"
                    }`}
                  >
                    {moment(new Date(item.createdAt)).format(
                      "HH:mm:ss"
                    )}
                  </span>
                  <span
                    className={`${
                      item.betRecordType === "BetDown" ? "red" : "green"
                    }`}
                  >
                    {item.betRecordUnit}
                  </span>
                  <span
                    className={`${
                      item.betRecordType === "BetDown" ? "red" : "green"
                    }`}
                  >
                    {number_to_price(item.betRecordAmountIn)}
                  </span>
                  <span
                    className={`${
                      item.betRecordType === "BetDown" ? "red" : "green"
                    }`}
                  >
                    {item.betRecordResult === null
                      ? ""
                      : item.betRecordResult !== "win"
                      ? "Thua"
                      : "Thắng"}
                  </span>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
