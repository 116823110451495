import { number_to_price } from "helper/common";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { connect, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Service from "./../../services/request";
// import { toast } from 'react-toastify';

function LayoutPage(props) {
  const { Component, className = "", classNameChart = "" } = props;
  const [linkSupport, setLinkSupport] = useState(null);
  const [homePageUrl, setHomePageUrl] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    Service.send({
      method: "post",
      path: "Maintain/getSystemStatus",
    }).then((result) => {
      console.log(result);
      if (result && result.statusCode === 200) {
        setLinkSupport(result.data.telegramGroupUrl);
        setHomePageUrl(result.data.homePageUrl);
      }
    });
  }, []);

  useEffect(() => {
    const bodyId = document.getElementById("body-root");
    bodyId.classList.remove("loginPage");

    if (className !== "") {
      bodyId.classList.add(className);
    } else {
      bodyId.classList.toggle("id");
    }
  }, [className]);

  const handleGetSystem = () => {
    Service.send({
      method: "post",
      path: "Maintain/getSystemStatus",
      data: {},
    }).then((result) => {
      if (result) {
        const { statusCode, data } = result;
        if (statusCode === 200) {
          // setSystemConfig(data)
        }
      }
    });
  };

  useEffect(() => {
    let mobileHeader = document.getElementById("headMobile");
    let webHeader = document.getElementById("headWeb");
    window.onscroll = function () {
      scrollFunction(mobileHeader, webHeader);
    };
    handleGetSystem();
  }, []);

  function scrollFunction(mobileHeader, webHeader) {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      mobileHeader.style.background = "#fff";
      webHeader.style.background = "#fff";
    } else {
      mobileHeader.style.background = "transparent";
      webHeader.style.background = "#fff";
    }
  }

  const { pathname } = props.location;

  function handleLogout(e) {
    e.preventDefault();
    dispatch({ type: "USER_RESET" });
    setTimeout(() => {
      props.history.push("/");
    }, 200);
  }

  const user = useSelector((state) => state.member);
  const history = useHistory();
  let newWallets = user.wallets;
  if (!newWallets || !newWallets[0]) {
    history.push("/");
    window.localStorage.clear();
    newWallets = [];
  }

  const handleGetUserDetail = (message) => {
    Service.send({
      method: "post",
      path: "User/getDetailUserById",
      data: { id: user.userId },
    }).then((result) => {
      if (result) {
        const { statusCode, data } = result;
        if (statusCode === 200 && user.userId === data.userId) {
          dispatch({ type: "USER_DETAILS_UPDATE", data: data });
          if (message) {
            window.sweetAlert("", message, "success");
          }
        }
      }
    });
  };

  return (
    <>
      {/* mobile */}
      <div id="mobile">
        <header id="headMobile">
          <div className={`nav container `}>
            <div>
              <a href= {homePageUrl || "https://sixvnn.com"} className="nav-logo">
                <img
                  src="./assets/images/logo.png"
                  alt=""
                  height={50}
                  width={200}
                />
              </a>
            </div>
            <button className="hamburger hamburger--collapse" type="button">
              {" "}
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
          </div>
          <div className="index-link-block">
            <div className="group index">
              {/* <div className="main-item show">
      					<h3 style={{color: 'white'}}>Điều hướng</h3>
      					<div className="unfold-btn"> <img src="img/nunu/icon_open.svg" className="open" alt="" /> <img src="img/nunu/icon_close.svg" className="close show" alt="" /> </div>
      				</div> */}
              <div className="secondary-item show">
                <a href={homePageUrl || "https://sixvnn.com"}>Trang Chủ</a>
                {/* <a href="/page_about">關於我們</a> */}
                <a href="/trade">Thị Trường Giao Dịch</a>
                {/* <a href="/page_product">遊戲中心</a> 
                <a href="/page_price">優惠活動</a> 
                <a href="/page_easy">新手教學</a> */}
                {/* <a href="">Dịch vụ chăm sóc khách</a> */}
                <a href="/member_center">Trung Tâm Thành Viên</a>
                <a href={linkSupport} rel="noopener noreferrer" target="_blank">
                  Hỗ Trợ
                </a>
                <a
                  href="logout"
                  onClick={(e) => {
                    handleLogout(e);
                  }}
                >
                  Đăng xuất
                </a>
              </div>
            </div>
          </div>
        </header>
      </div>

      {/* desktop */}
      <div id="web">
        <header id="headWeb">
          <div
            className={`nav container ${
              pathname === "/member_center" ? "dashboardContainer" : ""
            }`}
          >
            <div className="logo-container">
              <a href={homePageUrl || "https://sixvnn.com"} className="nav-logo">
                <img src="./assets/images/logo.png" alt="" height={50} />
              </a>
              {user && (
                <div className="user-info">
                  <div className="wallet-frame">
                    <i class="fa fa-user" aria-hidden="true"></i>
                    XIN CHÀO
                    <span
                      style={{ marginLeft: "5px" }}
                      id="user-account"
                      className="id"
                    >
                      {" "}
                      {user.username || ""}
                    </span>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="wallet-frame">
                      {" "}
                      Số Dư Tài Khoản:{" "}
                      <span
                        style={{ paddingLeft: "5px" }}
                        id="wallet"
                        ckt-name="balance"
                      >
                        {" "}
                        {newWallets[0]
                          ? number_to_price(newWallets[0].balance)
                          : "0.0"}
                      </span>
                    </div>
                    <div
                      onClick={() => {
                        handleGetUserDetail("Làm mới thành công");
                      }}
                      className="nav-btnGroup"
                    >
                      <span>
                        {" "}
                        <img
                          style={{ cursor: "pointer" }}
                          className="mobile-icon"
                          src="../../img/nunu/svgs/refreshIcon.svg"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="right-index-link-block">
              <a
                id="trangchu"
                className="right-index-link-block-highlight"
                href={homePageUrl || "https://sixvnn.com"}
              >
                Trang Chủ
              </a>
              <a
                id="thitruong"
                className="right-index-link-block-highlight"
                href="/trade"
              >
                Thị Trường Giao Dịch
              </a>
              {/* <a href="/page_product">遊戲中心</a> <a href="/page_price">優惠活動</a> <a href="/page_easy">新手教學</a> */}
              {/* <a href="">Dịch vụ chăm sóc khách</a> */}
              <a
                id="thanh vien"
                className="right-index-link-block-highlight"
                href="/member_center"
              >
                Trung Tâm Thành Viên
              </a>
              <a
                onClick={(e) => {
                  handleLogout(e);
                }}
                href="logout"
                className="btn1 "
              >
                Đăng Xuất
              </a>
            </div>
          </div>
        </header>
      </div>
      <Component {...props} className={classNameChart} />
      <footer className="newsletter_right_w3_agileits bg-dark pymd-5 py-4">
        <div className="container">
          <div className="copyright text-center">
            {/* <p className="copy-right-w3ls">© 2020 TEAM INTERNATIONAL ALL RIGHTS RESERVED</p> */}
          </div>
        </div>
      </footer>
    </>
  );
}

const mapStateToProps = (state) => ({
  // member: state.member || {},
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutPage);
