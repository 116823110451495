import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import {
  elderRay,
  ema,
  discontinuousTimeScaleProviderBuilder,
  Chart,
  ChartCanvas,
  CurrentCoordinate,
  BarSeries,
  CandlestickSeries,
  // ElderRaySeries,
  LineSeries,
  // MovingAverageTooltip,
  // OHLCTooltip,
  // SingleValueTooltip,
  lastVisibleItemBasedZoomAnchor,
  XAxis,
  YAxis,
  CrossHairCursor,
  EdgeIndicator,
  MouseCoordinateX,
  MouseCoordinateY,
  // ZoomButtons,
  withDeviceRatio,
  withSize,
  // plotDataLengthBarWidth,
} from "react-financial-charts";
// import { utcMinute } from 'd3-time';
import { yExtentsCalculator } from "services/candleStickService";
import {
  axisStylesX,
  axisStylesY,
  coordinateStyles,
  crossHairStyles,
} from "constants/chart";
import moment from "moment";
import OHLCTooltip from "utils/OHLCTooltip";
import useWindowDimensions from "helper/useWindowDimensions";
// import { initialChartData } from "./data";
const openCloseColor = (d) => (d.close > d.open ? "#36FFB5" : "#FF715E");
const FinancialChart = (props) => {
  const {
    priceDisplayFormat,
    margin,
    initialChartData,
    betRecordsListResult,
    height,
    ratio,
    width,
    showLineMA,
  } = props;
  const [resetCount, setResetCount] = useState(0); 
  const ScaleProvider =
    discontinuousTimeScaleProviderBuilder().inputDateAccessor(
      (d) => new Date(d.date)
    );
    const { width: windowWidth } = useWindowDimensions();

  // useEffect(() => {
  //   if (betRecordsListResult.length === 0) return;

  //   let newItem =
  //     betRecordsListResult[
  //       betRecordsListResult.length - 1
  //     ].gameRecordUnit.toLowerCase() !== "BTC-usd".toLowerCase()
  //       ? undefined
  //       : betRecordsListResult[betRecordsListResult.length - 1];

  //   if (newItem) {
  //     newItem.gameRecordSection = newItem.gameRecordSection * 1 + 1;
  //     newItem.gameRecordSection = newItem.gameRecordSection + "";

  //     setCandleList([
  //       ...candleList,
  //       {
  //         date: moment(newItem.gameRecordSection, "YYYYMMDDHHmm").toDate(),
  //         open: newItem.open,
  //         high: newItem.high,
  //         low: newItem.low,
  //         close: newItem.close,
  //       },
  //     ]);
  //   }
  //   // candlestickStream.onAddCandlestickViaInternalApi(newItem);
  // }, [betRecordsListResult.length]);

  //   const height = 700;
  //   const width = 900;
  //   const margin = { left: 0, right: 48, top: 0, bottom: 24 };
  if (!height || !ratio || !width) return null;
  // const dataMaNew = dataMA?.filter(item => item?.gameRecordValue).reverse();
  // let index = 0;
  const ema10 = ema()
    .id(1)
    .options({ windowSize: 10 })
    .merge((d, c) => {
      // index++;
      d.ema10 = c;
      // index + dataMaNew?.length > 491 ? parseFloat(dataMaNew?.[491 - index]?.gameRecordValue?.split(';')[1]) : c;
    })
    .accessor((d) => d.ema10);
  // let index2 = 0;
  const ema50 = ema()
    .id(2)
    .options({ windowSize: 50 })
    .merge((d, c) => {
      // index2++;
      d.ema50 = c;
      // index2 + dataMaNew?.length > 491 ? parseFloat(dataMaNew?.[491 - index2]?.gameRecordValue?.split(';')[2]) : c;
    })
    .accessor((d) => d.ema50);
  const elder = elderRay();
  elder(ema50(ema10(initialChartData)));

  const { data, xScale, xAccessor, displayXAccessor } = ScaleProvider([
    ...initialChartData,
    {
      date: moment(initialChartData[initialChartData?.length - 1]?.date)
        .add(1, "m")
        .format(),
    },
    {
      date: moment(initialChartData[initialChartData?.length - 1]?.date)
        .add(2, "m")
        .format(),
    },
  ]);

  const min = xAccessor(data[Math.max(0, data.length - parseInt(width / 5))]);
  const max = xAccessor(data[data.length - 1]);
  const xExtents = [min, max + 1];

  const gridHeight = height - margin.top - margin.bottom;

  const elderRayHeight = 100;
  const barChartHeight = 50;
  const barChartOrigin = (_, h) => [0, h - barChartHeight - 50];
  const chartHeight = gridHeight - elderRayHeight;
  const dateTimeFormat = "%H:%M";
  const timeDisplayFormat = timeFormat(dateTimeFormat);

  return (
    <ChartCanvas
      height={height}
      ratio={ratio}
      width={width}
      margin={margin}
      seriesName={`Chart`}
      data={[...data]}
      xScale={xScale}
      xAccessor={xAccessor}
      displayXAccessor={displayXAccessor}
      xExtents={xExtents}
      zoomAnchor={lastVisibleItemBasedZoomAnchor}
      pointsPerPxThreshold={0.06}
      // clamp={true}
      // disableZoom={true}
      //minPointsPerPxThreshold={0.019}
    >
      {/* Volume Chart */}
      <Chart
        id={1}
        height={barChartHeight}
        origin={barChartOrigin}
        yExtents={(d) => d.volume}
      >
        <BarSeries
          fillStyle={(d) =>
            d.close > d.open
              ? "rgba(38, 166, 154, 0.3)"
              : "rgba(239, 83, 80, 0.3)"
          }
          yAccessor={(d) => d.volume}
          widthRatio={ windowWidth > 500 ? 0.8 : 0.5}
        />
        <XAxis {...axisStylesX} showGridLines />
      </Chart>
      {/* Price Chart */}
      <Chart
        id={3}
        height={chartHeight}
        yExtentsCalculator={yExtentsCalculator}
      >
        <YAxis {...axisStylesY} showGridLines />
        <EdgeIndicator
          fontSize={12}
          itemType="last"
          fill={openCloseColor}
          lineStroke={openCloseColor}
          displayFormat={priceDisplayFormat}
          yAccessor={(d) => d.close}
          textFill={"#1D1D42"}
        />
        {window.innerWidth > 900 && (
          <>
            <MouseCoordinateX
              fontSize={10}
              displayFormat={timeDisplayFormat}
              {...coordinateStyles}
            />
            <MouseCoordinateY
              fontSize={10}
              displayFormat={format(".4f")}
              {...coordinateStyles}
            />
            <CurrentCoordinate
              yAccessor={ema10.accessor()}
              fillStyle={ema10.stroke()}
            />
            <CurrentCoordinate
              yAccessor={ema50.accessor()}
              fillStyle={ema50.stroke()}
            />
          </>
        )}

        <CandlestickSeries
          fill={(d) => (d.close > d.open ? "#36D572" : "#EC455F")}
          clip={true} 
          widthRatio={ windowWidth > 500 ? 0.8 : 0.5}

        />
        {showLineMA && (
          <>
            <LineSeries
              yAccessor={ema10.accessor()}
              strokeStyle={ema10.stroke()}
            />
            <LineSeries
              yAccessor={ema50.accessor()}
              strokeStyle={ema50.stroke()}
            />
          </>
        )}

        <OHLCTooltip
          fontSize={window.innerWidth > 500 ? "12" : "10"}
          origin={[8, 1]}
          textFill={openCloseColor}
          className="react-no-select"
          // f={f}
        />
        {/* <ZoomButtons onReset={() => setResetCount(resetCount + 1)}/> */}
      </Chart>
      <CrossHairCursor {...crossHairStyles}></CrossHairCursor>
    </ChartCanvas>
  );
};

FinancialChart.propTypes = {
  dateTimeFormat: PropTypes.string,
  height: PropTypes.number,
  margin: PropTypes.object,
  priceDisplayFormat: PropTypes.func,
  ratio: PropTypes.number,
  width: PropTypes.number,
};

FinancialChart.defaultProps = {
  dateTimeFormat: "%d %b '%y \xa0 %H:%M",
  height: 0,
  margin: { left: 0, right: 54, top: 4, bottom: -15 },
  priceDisplayFormat: format(""),
  ratio: 2,
  width: 0,
};

export const TradingChart = withSize({
  style: {
    height:
      window.innerWidth > 768 ? "calc(100vh - 306px)" : "calc(100vh - 366px)",
    minHeight: window.innerWidth > 768 ? "430px" : "360px",
  },
})(withDeviceRatio()(FinancialChart));
