import React, { useEffect, useState } from "react";
import _ from "lodash";
import "antd/dist/antd.css";
import { useSelector } from "react-redux";
import Connection from "./connection";
import { number_to_price } from "../../helper/common";
import Service from "./../../services/request";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Modal } from "antd";
import { BtcChart } from "components/BtcChart/BtcChart";
import { RightMenu } from "./rightMenu";
import { TradingMarket } from "./TradingMarket";
import { MyTransaction } from "./myTransaction";
import { TradingChart } from "components/TradingChart/traderViewChart";
const BTC = "BTC";
const ETH = "ETH";
const BET_TYPE = {
  BIG: "BetBig",
  SMALL: "BetSmall",
  ODD: "BetOdd",
  EVEN: "BetEven",
  UP: "BetUp",
  DOWN: "BetDown",
};
const ListIcon = [
  "../../img/nunu/svgs/draw1.svg",
  "../../img/nunu/svgs/draw2.svg",
  "../../img/nunu/svgs/draw3.svg",
  "../../img/nunu/svgs/draw4.svg",
  "../../img/nunu/svgs/draw5.svg",
  "../../img/nunu/svgs/draw6.svg",
  "../../img/nunu/svgs/draw7.svg",
];
const ListIcon2 = [
  "../../img/nunu/svgs/draw8.svg",
  "../../img/nunu/svgs/draw9.svg",
];

const ListIcon3 = [
  "../../img/nunu/svgs/draw10.svg",
  "../../img/nunu/svgs/draw11.svg",
  "../../img/nunu/svgs/draw12.svg",
  "../../img/nunu/svgs/draw13.svg",
];
const ListIcon4 = ["../../img/nunu/svgs/draw14.svg"];
function Chart(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch(props);
  const {
    payloadETH,
    payloadADA,
    payloadBNB,
    payloadDOGE,
    payloadDOT,
    payloadLTC,
    payloadXRP,
    payloadBTC,
    payloadSERVERTIME,
    payloadCOUNTDOWN,
    payload,
    betRecordsListLive,
    mqttDisconnect,
    candlestickPayload,
    betRecordsListResult,
  } = props;

  const [betRecordsList, setBetRecordsList] = useState([]);
  const [candlesticks, setCandlesticks] = useState([]);
  const [leftTab, setLeftTab] = useState("ttgd");
  const [betRecordChange, setBetRecordChange] = useState(false);
  const [type, setType] = useState("");
  const [tmpPrice, setTmpPice] = useState(100000);
  const { typeMoney, setTypeMoney} = props;
  const user = useSelector((state) => state.member);
  const history = useHistory();
  let newWallets = user.wallets;
  if (!newWallets || !newWallets[0]) {
    history.push("/");
    window.localStorage.clear();
    newWallets = [];
  }
  const DEFAULT_DATA = {
    userId: user.userId,
    betRecordAmountIn: "",
    // betRecordType:  BET_TYPE.UP, ////Bỏ chọn cách chơi mặc định
    betRecordUnit: `${typeMoney}-USD`,
  };
  const [dataBetRecord, setDataBetRecord] = useState(DEFAULT_DATA);

  useEffect(() => {
    if (candlestickPayload.length) {
      candlestickPayload.forEach((element) => {
        element.date = moment(element.gameRecordSection, "YYYYMMDDHHmm");
      });
      setCandlesticks(candlestickPayload.reverse())
    }
  }, [candlestickPayload]);

  const handleBetRecords = (data) => {
    if (payloadCOUNTDOWN < 10) {
      return;
    }
    Service.send({
      method: "post",
      path: "BetRecords/insert",
      data,
    }).then((result) => {
      if (result) {
        const { statusCode, message, data = {} } = result;
        setBetRecordChange(true);
        if (statusCode === 200) {
          setDataBetRecord(DEFAULT_DATA);
          handleGetUserDetail();

          handleCallListBet();
          setTimeout(() => {
            setBetRecordChange(false);
            window.sweetAlert(
              "",
              `Đặt lệnh thành công với Mã GD: ${
                data.ID
              }, Số tiền: ${number_to_price(data.betRecordAmountIn || 0)}, ${
                data.betRecordType === "BetUp"
                  ? "Lên"
                  : data.betRecordType === "BetOdd"
                  ? "Lẻ"
                  : data.betRecordType === "BetEven"
                  ? "Chẵn"
                  : "Xuống"
              }`,
              "success"
            );
          }, 1000);
        } else {
          window.sweetAlert("", message, "warning");
        }
      }
    });
  };

  const handleRenderTextType = (type) => {
    if (type === BET_TYPE.UP) {
      return { text: "Lên", type: "result_up" };
    } else if (type === BET_TYPE.DOWN) {
      return { text: "Xuống", type: "result_down" };
    } else if (type === BET_TYPE.EVEN) {
      return { text: "Chẵn", type: "result_even" };
    }
    return { text: "Lẻ", type: "result_odd" };
  };

  const handleCallListBet = () => {
    Service.send({
      method: "post",
      path: "BetRecords/getList",
      data: {
        filter: {
          userId: user.userId,
        },
        skip: 0,
        limit: 20,
      },
    }).then((result) => {
      if (result) {
        const { statusCode, data } = result;
        setBetRecordsList([]);
        if (statusCode === 200) {
          setBetRecordsList(data.data);
        }
      }
    });
  };

  // useEffect(() => {
  //   if (betRecordsListResult.length === 0) return;

  //   let newItem =
  //     betRecordsListResult[
  //       betRecordsListResult.length - 1
  //     ].gameRecordUnit.toLowerCase() !== (typeMoney + "-usd").toLowerCase()
  //       ? undefined
  //       : betRecordsListResult[betRecordsListResult.length - 1];

  //   if (newItem) {
  //     newItem.gameRecordSection = newItem.gameRecordSection * 1 + 1;
  //     newItem.gameRecordSection = newItem.gameRecordSection + ""
  //     candlestickPayload.push({
  //       date: moment(newItem.gameRecordSection),
  //       open:newItem.open,
  //       high:newItem.high,
  //       low: newItem.low,
  //       close: newItem.close
  //     })
  //   }
  // }, [betRecordsListResult]);

  const handleGetUserDetail = (message) => {
    Service.send({
      method: "post",
      path: "User/getDetailUserById",
      data: { id: user.userId },
    }).then((result) => {
      if (result) {
        const { statusCode, data } = result;
        if (statusCode === 200 && user.userId === data.userId) {
          dispatch({ type: "USER_DETAILS_UPDATE", data: data });
          if (message) {
            window.sweetAlert("", message, "success");
          }
        }
      }
    });
  };

  useEffect(() => {
    handleCallListBet();
    handleGetUserDetail();
    setInterval(() => {
      handleCallListBet();
    }, 20000);
  }, []);

  function handleOnclickIcon() {
    window.sweetAlert(
      "",
      "Tính năng phân tích chỉ dùng cho tài khoản VIP",
      "warning"
    );
  }

  const ethPrice = payloadETH.price ? (+payloadETH.price).toFixed(4) : null;
  const adaPrice = payloadADA.price ? (+payloadADA.price).toFixed(4) : null;
  const bnbPrice = payloadBNB.price ? (+payloadBNB.price).toFixed(4) : null;
  const dogePrice = payloadDOGE.price ? (+payloadDOGE.price).toFixed(4) : null;
  const dotPrice = payloadDOT.price ? (+payloadDOT.price).toFixed(4) : null;
  const ltcPrice = payloadLTC.price ? (+payloadLTC.price).toFixed(4) : null;
  const xrpPrice = payloadXRP.price ? (+payloadXRP.price).toFixed(4) : null;
  const btcPrice = payloadBTC.price ? (+payloadBTC.price).toFixed(4) : null;

  return (
    <>
      <div
        className="tradingview-widget-container"
        style={{ width: "100%", height: "47px" }}
      >
        <iframe
          scrolling="no"
          allowTransparency="true"
          frameBorder={0}
          src="https://s.tradingview.com/embed-widget/ticker-tape/?locale=uk#%7B%22symbols%22%3A%5B%7B%22proName%22%3A%22FOREXCOM%3ASPXUSD%22%2C%22title%22%3A%22S%26P%20500%22%7D%2C%7B%22proName%22%3A%22FOREXCOM%3ANSXUSD%22%2C%22title%22%3A%22Nasdaq%20100%22%7D%2C%7B%22description%22%3A%22BIT%2FJPY%22%2C%22proName%22%3A%22BITFINEX%3ABTCJPY%22%7D%2C%7B%22description%22%3A%22ASXGOLD%22%2C%22proName%22%3A%22ASX%3AGOLD%22%7D%2C%7B%22description%22%3A%22AUDSILVER%22%2C%22proName%22%3A%22FX_IDC%3AXAGAUD%22%7D%5D%2C%22showSymbolLogo%22%3Atrue%2C%22colorTheme%22%3A%22dark%22%2C%22isTransparent%22%3Atrue%2C%22displayMode%22%3A%22adaptive%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A44%2C%22utm_source%22%3A%22xgame.gd11.sunbur77.club%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22ticker-tape%22%7D"
          style={{ boxSizing: "border-box", height: "72px", width: "100%" }}
        />
      </div>

      <div className="container-fluid">
        <div style={{ background: "#19191a" }}>
          <div
            className="flex-between"
            style={{ background: "#19191a", padding: "0 16px" }}
          >
            <div
              onClick={() => setLeftTab("ttgd")}
              className={`frame-title ${leftTab === "ttgd" && "active"}`}
            >
              Thị Trường Giao Dịch
            </div>
            <div
              onClick={() => setLeftTab("chart")}
              className={`mobile frame-title ${
                leftTab === "chart" && "active"
              }`}
            >
              Biểu đồ
            </div>
            <div
              onClick={() => setLeftTab("gdct")}
              className={`frame-title ${leftTab === "gdct" && "active"}`}
            >
              Giao dịch của tôi
            </div>
          </div>
          {leftTab === "ttgd" ? (
            <TradingMarket
              betRecordsListLive={betRecordsListLive}
              handleRenderTextType={handleRenderTextType}
            />
          ) : leftTab === "gdct" ? (
            <>
              <MyTransaction betRecordsList={betRecordsList} />
            </>
          ) : null}
        </div>

        <div
          className={`row chartsection-container ${
            leftTab === "chart" ? "mobile" : "desktop"
          }`}
        >
          <div className="canvas-run">
            <div className="section-trend">
              <div style={{ display: "flex", height: "100%" }}>
                <div className={"drawing-toolbar"}>
                  {ListIcon.map((item) => (
                    <div
                      onClick={() => {
                        handleOnclickIcon();
                      }}
                      className="drawing-toolbar__content"
                    >
                      <img src={item} style={{ cursor: "pointer" }}></img>
                    </div>
                  ))}
                  <div className="drawing-toolbar__line"></div>
                  {ListIcon2.map((item) => (
                    <div
                      onClick={() => {
                        handleOnclickIcon();
                      }}
                      className="drawing-toolbar__content"
                    >
                      <img src={item} style={{ cursor: "pointer" }}></img>
                    </div>
                  ))}
                  <div className="drawing-toolbar__line"></div>
                  {ListIcon3.map((item) => (
                    <div
                      onClick={() => {
                        handleOnclickIcon();
                      }}
                      className="drawing-toolbar__content"
                    >
                      <img src={item} style={{ cursor: "pointer" }}></img>
                    </div>
                  ))}
                  <div className="drawing-toolbar__line"></div>
                  {ListIcon4.map((item) => (
                    <div
                      onClick={() => {
                        handleOnclickIcon();
                      }}
                      className="drawing-toolbar__content"
                    >
                      <img src={item} style={{ cursor: "pointer" }}></img>
                    </div>
                  ))}
                </div>

                <div
                  style={{
                    width: "100%",
                    background: "#202022",
                  }}
                >
                  <div className="game-body canvas">
                    <div className="game-box">
                      <TradingChart
                        initialChartData={candlesticks}
                        showLineMA={true} 
                      />

                      {/* <BtcChart
                        betRecordsListResult={betRecordsListResult}
                        candlestickPayload={candlestickPayload}
                        typeMoney={typeMoney}
                        payload={payload}
                      /> */}

                      {/* <Connection
                        typeMoney={typeMoney}
                        payload={payload}
                        betRecordChange={betRecordChange}
                      /> */}
                    </div>
                  </div>

                  <div className="bet-area1 mobile">
                    <section className="section section-binary">
                      <nav className="nav">
                        <div className="logo">
                          <img src="./assets/images/loading.gif" />
                          <a>
                            <b> Thời gian: </b>
                            <span id="now_datetime">
                              {" "}
                              {payloadSERVERTIME}
                            </span>
                          </a>
                        </div>
                      </nav>
                      <div className="block">
                        <div className="tap-content">
                          <div className="tab-pane active" id="binaryBox">
                            <div
                              className="tab-infomation"
                              style={{
                                width: "100%",
                                textAlign: "center",
                                marginBottom: "20px",
                              }}
                            >
                              <div className="tab-type">
                                <p>Thị Trường:</p>
                                <select
                                  id="right_roomList"
                                  className="custom-amount-select"
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    if (value === BTC || value === ETH) {
                                      setTypeMoney(value);
                                      mqttDisconnect();
                                      setTimeout(() => {
                                        window.location.href = `${window.location.origin}${window.location.pathname}?type=${value}`;
                                      }, 500);
                                    } else {
                                      window.sweetAlert(
                                        "",
                                        "Đang bị khóa",
                                        "warning"
                                      );
                                    }
                                  }}
                                  value={typeMoney}
                                  style={{ maxWidth: "unset" }}
                                >
                                  <option value={BTC}>BTC/USD</option>
                                  <option value={ETH}>ETH/USD</option>
                                  <option value={"ADA/USD"}>ADA/USD</option>
                                  {/* <option value={"BNB/USD"}>BNB/USD</option> */}
                                  <option value={"DOGE/USD"}>DOGE/USD</option>
                                  <option value={"DOT/USD"}>DOT/USD</option>
                                  <option value={"LTC/USD"}>LTC/USD</option>
                                  <option value={"XRP/USD"}>XRP/USD</option>
                                  <option value={"BTC/USD"}>BTC/USD</option>
                                </select>
                              </div>
                              <div className="tab-current sub-title2">
                                <div className="name">
                                  Giao dịch:{" "}
                                  <span id="gameid" className="now_period">
                                    {moment()
                                      .add(1, "m")
                                      .format("YYYYMMDDHHmm")}
                                  </span>
                                </div>
                                <div className="countdown-area">
                                  Đếm ngược:{" "}
                                  <div
                                    className="countdown"
                                    id="countdown"
                                    last_seconds={4}
                                  >
                                    {payloadCOUNTDOWN}
                                    s
                                  </div>
                                  <img
                                    style={{ marginLeft: "5px" }}
                                    src="./assets/images/count.gif"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="wrapup">
                              <form
                                id="Game_form"
                                className="bet-2"
                                onSubmit="lightBox('#lightBoxConfirm');return false;"
                              >
                                <div className="bet-infoleft">
                                  <div className="wallet-frame">
                                    <i
                                      class="fa fa-user"
                                      aria-hidden="true"
                                    ></i>
                                    XIN CHÀO
                                    <span
                                      style={{ marginLeft: "5px" }}
                                      id="user-account"
                                      className="id"
                                    >
                                      {" "}
                                      {user.username || ""}
                                    </span>
                                  </div>
                                  <div>
                                    <div className="radio-group">
                                      <div className="choose-radio temp-up">
                                        <input
                                          onChange={(e) => {
                                            const { checked } = e.target;
                                            if (checked) {
                                              setDataBetRecord({
                                                ...dataBetRecord,
                                                betRecordType: BET_TYPE.UP,
                                              });
                                            }
                                          }}
                                          checked={
                                            dataBetRecord.betRecordType ===
                                            BET_TYPE.UP
                                          }
                                          id="binary_playType_A"
                                          name="binary_playType_big_or_small"
                                          type="radio"
                                          bet_value="45_1"
                                        />
                                        <label
                                          htmlFor="binary_playType_A"
                                          style={{
                                            borderRadius: "10px 0 0 10px",
                                          }}
                                        >
                                          Lên <br />
                                          <span className="bet1">90%</span>
                                        </label>
                                      </div>
                                      <div className="choose-radio temp-down">
                                        <input
                                          onChange={(e) => {
                                            const { checked } = e.target;
                                            if (checked) {
                                              setDataBetRecord({
                                                ...dataBetRecord,
                                                betRecordType: BET_TYPE.DOWN,
                                              });
                                            }
                                          }}
                                          checked={
                                            dataBetRecord.betRecordType ===
                                            BET_TYPE.DOWN
                                          }
                                          id="binary_playType_B"
                                          name="binary_playType_big_or_small"
                                          type="radio"
                                          bet_value="45_2"
                                        />
                                        <label
                                          htmlFor="binary_playType_B"
                                          style={{
                                            borderRadius: "0 10px 10px 0",
                                          }}
                                        >
                                          Xuống <br />
                                          <span className="bet1">90%</span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="bet-inforight">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="wallet-frame">
                                      {" "}
                                      Số Dư Tài Khoản:{" "}
                                      <span
                                        style={{ paddingLeft: "5px" }}
                                        id="wallet"
                                        ckt-name="balance"
                                      >
                                        {" "}
                                        {newWallets[0]
                                          ? number_to_price(
                                              newWallets[0].balance
                                            )
                                          : "0.0"}
                                      </span>
                                    </div>
                                    <div
                                      onClick={() => {
                                        handleGetUserDetail(
                                          "Làm mới thành công"
                                        );
                                      }}
                                      className="nav-btnGroup"
                                    >
                                      <span>
                                        {" "}
                                        <img
                                          style={{ cursor: "pointer" }}
                                          className="mobile-icon"
                                          src="../../img/nunu/svgs/refreshIcon.svg"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="sub-content">
                                    <div
                                      className="amount-select"
                                      style={{ display: "flex" }}
                                    >
                                      <p>Số tiền</p>
                                      <select
                                        className="custom-amount-select"
                                        onChange={(e) => {
                                          const { value } = e.target;
                                          setTmpPice(value);
                                        }}
                                        value={tmpPrice}
                                      >
                                        {/* <option value={0}>tùy chỉnh.</option> */}
                                        <option value={20000}>20K</option>
                                        <option value={50000}>50K</option>
                                        <option value={100000}>100K</option>
                                        <option value={200000}>200K</option>
                                        <option value={500000}>500K</option>
                                        <option value={1000000}>1M</option>
                                        <option value={2000000}>2M</option>
                                        <option value={3000000}>3M</option>
                                        <option value={5000000}>5M</option>
                                        <option value={10000000}>10M</option>
                                        <option value={20000000}>20M</option>
                                        <option value={30000000}>30M</option>
                                        <option value={50000000}>50M</option>
                                        <option value={100000000}>100M</option>
                                        <option value={200000000}>200M</option>
                                        <option value={300000000}>300M</option>
                                        <option value={500000000}>500M</option>
                                      </select>
                                    </div>
                                  </div>
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      //Bỏ chọn cách chơi mặc định
                                      if (
                                        dataBetRecord.betRecordType ===
                                        undefined
                                      ) {
                                        window.sweetAlert(
                                          "",
                                          "Vui lòng chọn 1 cách chơi (Lên / Xuống)",
                                          "warning"
                                        );
                                        return;
                                      }

                                      if (payloadCOUNTDOWN < 10) {
                                        window.sweetAlert(
                                          "",
                                          "Phiên giao dịch sẽ thực hiện sau 10s",
                                          "warning"
                                        );
                                      } else {
                                        if (
                                          dataBetRecord.betRecordAmountIn <= 0
                                        ) {
                                          dataBetRecord.betRecordAmountIn =
                                            tmpPrice;
                                        }

                                        if (
                                          dataBetRecord.betRecordAmountIn <
                                          10000
                                        ) {
                                          window.sweetAlert(
                                            "",
                                            "Số tiền phải lớn hơn hoặc bằng 10k",
                                            "warning"
                                          );
                                        } else if (
                                          dataBetRecord.betRecordAmountIn >
                                          500000000
                                        ) {
                                          window.sweetAlert(
                                            "",
                                            "Số tiền phải bé hoặc bằng 500tr",
                                            "warning"
                                          );
                                        } else if (
                                          newWallets[0] &&
                                          newWallets[0].balance <
                                            dataBetRecord.betRecordAmountIn
                                        ) {
                                          window.sweetAlert(
                                            "",
                                            "Số tiền vượt quá tài khoản hiện có",
                                            "warning"
                                          );
                                        } else {
                                          window.sweetAlert(
                                            {
                                              title: "Xác nhận giao dịch",
                                              html: true,
                                              customClass: "sweetCustorm",
                                              text: `
                                      <table class="table__bet">
                                        <tr>
                                          <td>Tên sản phẩm</td>
                                          <td>${typeMoney}/USD</th>                                     
                                        </tr>
                                        <tr>
                                          <td>Loại đầu tư</td>
                                          <td>${
                                            handleRenderTextType(
                                              dataBetRecord.betRecordType
                                            ).text
                                          }</td>                                  
                                        </tr>
                                        <tr>
                                          <td>Hoàn lại vốn đầu tư</td>
                                          <td>90%</td>                                    
                                        </tr>
                                        <tr>
                                          <td>Giá sản phẩm</td>
                                          <td>${
                                            typeMoney === BTC
                                              ? btcPrice
                                                ? btcPrice
                                                : "----"
                                              : ethPrice
                                              ? ethPrice
                                              : "----"
                                          } </td>                                    
                                        </tr>
                                        <tr>
                                        <td>Số tiền giao dịch</td>
                                        <td>${number_to_price(
                                          dataBetRecord.betRecordAmountIn
                                        )}</td>                                    
                                        </tr>
                                      </table>
                                      `,
                                              icon: "warning",
                                              showCancelButton: true,
                                              confirmButtonColor: "#00614A",
                                              confirmButtonText:
                                                "Xác nhận gửi lệnh",
                                              cancelButtonText: "Hủy yêu cầu",
                                              closeOnConfirm: true,
                                              closeOnCancel: true,
                                            },
                                            (isOke) => {
                                              if (isOke) {
                                                handleBetRecords(dataBetRecord);
                                              }
                                            }
                                          );
                                        }
                                      }
                                    }}
                                    type="submit"
                                    className="btn-buy"
                                  >
                                    Xác nhận giao dịch
                                  </button>
                                </div>
                              </form>
                            </div>{" "}
                            {/* wrapup */}
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                  <form
                    id="Game_form"
                    className="bet-2"
                    onSubmit="lightBox('#lightBoxConfirm');return false;"
                  >
                    <div className="bet-area1 desktop">
                      <section className="section section-binary">
                        <div className="bet-container">
                          <div className="bet-row">
                            <div className="logo">
                              <img src="./assets/images/loading.gif" />
                              <a>
                                <b> Thời gian: </b>
                                <span id="now_datetime">
                                  {" "}
                                  {payloadSERVERTIME}
                                </span>
                              </a>
                            </div>

                            <div
                              className="tab-infomation"
                              style={{
                                width: "100%",
                                textAlign: "center",
                                marginBottom: "20px",
                              }}
                            >
                              <div className="tab-type">
                                <p>Thị Trường:</p>
                                <select
                                  id="right_roomList"
                                  className="custom-amount-select"
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    if (value === BTC || value === ETH) {
                                      setTypeMoney(value);
                                      mqttDisconnect();
                                      setTimeout(() => {
                                        window.location.href = `${window.location.origin}${window.location.pathname}?type=${value}`;
                                      }, 500);
                                    } else {
                                      window.sweetAlert(
                                        "",
                                        "Đang bị khóa",
                                        "warning"
                                      );
                                    }
                                  }}
                                  value={typeMoney}
                                  style={{ maxWidth: "unset" }}
                                >
                                  <option value={BTC}>BTC/USD</option>
                                  <option value={ETH}>ETH/USD</option>
                                  <option value={"ADA/USD"}>ADA/USD</option>
                                  {/* <option value={"BNB/USD"}>BNB/USD</option> */}
                                  <option value={"DOGE/USD"}>DOGE/USD</option>
                                  <option value={"DOT/USD"}>DOT/USD</option>
                                  <option value={"LTC/USD"}>LTC/USD</option>
                                  <option value={"XRP/USD"}>XRP/USD</option>
                                  <option value={"BTC/USD"}>BTC/USD</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="bet-row">
                            <div className="tab-current sub-title2">
                              <div className="name">
                                GD:{" "}
                                <span id="gameid" className="now_period">
                                  {moment().add(1, "m").format("YYYYMMDDHHmm")}
                                </span>
                              </div>
                              <div className="countdown-area">
                                Đếm ngược:{" "}
                                <div
                                  className="countdown"
                                  id="countdown"
                                  last_seconds={4}
                                >
                                  {payloadCOUNTDOWN}
                                  s
                                </div>
                                <img
                                  style={{ marginLeft: "5px" }}
                                  src="./assets/images/count.gif"
                                />
                              </div>
                            </div>

                            <div className="radio-group">
                              <div className="choose-radio temp-up">
                                <input
                                  onChange={(e) => {
                                    const { checked } = e.target;
                                    if (checked) {
                                      setDataBetRecord({
                                        ...dataBetRecord,
                                        betRecordType: BET_TYPE.UP,
                                      });
                                    }
                                  }}
                                  checked={
                                    dataBetRecord.betRecordType === BET_TYPE.UP
                                  }
                                  id="binary_playType_A"
                                  name="binary_playType_big_or_small"
                                  type="radio"
                                  bet_value="45_1"
                                />
                                <label
                                  htmlFor="binary_playType_A"
                                  style={{
                                    borderRadius: "10px 0 0 10px",
                                  }}
                                >
                                  Lên <br />
                                  <span className="bet1">90%</span>
                                </label>
                              </div>
                              <div className="choose-radio temp-down">
                                <input
                                  onChange={(e) => {
                                    const { checked } = e.target;
                                    if (checked) {
                                      setDataBetRecord({
                                        ...dataBetRecord,
                                        betRecordType: BET_TYPE.DOWN,
                                      });
                                    }
                                  }}
                                  checked={
                                    dataBetRecord.betRecordType ===
                                    BET_TYPE.DOWN
                                  }
                                  id="binary_playType_B"
                                  name="binary_playType_big_or_small"
                                  type="radio"
                                  bet_value="45_2"
                                />
                                <label
                                  htmlFor="binary_playType_B"
                                  style={{
                                    borderRadius: "0 10px 10px 0",
                                  }}
                                >
                                  Xuống <br />
                                  <span className="bet1">90%</span>
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="bet-row">
                            <div className="bet-inforight">
                              <div className="sub-content">
                                <div className="amount-select">
                                  <p>Số tiền</p>
                                  <select
                                    className="custom-amount-select"
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      setTmpPice(value);
                                    }}
                                    value={tmpPrice}
                                  >
                                    {/* <option value={0}>tùy chỉnh.</option> */}
                                    <option value={20000}>20K</option>
                                    <option value={50000}>50K</option>
                                    <option value={100000}>100K</option>
                                    <option value={200000}>200K</option>
                                    <option value={500000}>500K</option>
                                    <option value={1000000}>1M</option>
                                    <option value={2000000}>2M</option>
                                    <option value={3000000}>3M</option>
                                    <option value={5000000}>5M</option>
                                    <option value={10000000}>10M</option>
                                    <option value={20000000}>20M</option>
                                    <option value={30000000}>30M</option>
                                    <option value={50000000}>50M</option>
                                    <option value={100000000}>100M</option>
                                    <option value={200000000}>200M</option>
                                    <option value={300000000}>300M</option>
                                    <option value={500000000}>500M</option>
                                  </select>
                                </div>
                              </div>
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  //Bỏ chọn cách chơi mặc định
                                  if (
                                    dataBetRecord.betRecordType === undefined
                                  ) {
                                    window.sweetAlert(
                                      "",
                                      "Vui lòng chọn 1 cách chơi (Lên / Xuống)",
                                      "warning"
                                    );
                                    return;
                                  }

                                  if (payloadCOUNTDOWN < 10) {
                                    window.sweetAlert(
                                      "",
                                      "Phiên giao dịch sẽ thực hiện sau 10s",
                                      "warning"
                                    );
                                  } else {
                                    if (dataBetRecord.betRecordAmountIn <= 0) {
                                      dataBetRecord.betRecordAmountIn =
                                        tmpPrice;
                                    }

                                    if (
                                      dataBetRecord.betRecordAmountIn < 10000
                                    ) {
                                      window.sweetAlert(
                                        "",
                                        "Số tiền phải lớn hơn hoặc bằng 10k",
                                        "warning"
                                      );
                                    } else if (
                                      dataBetRecord.betRecordAmountIn >
                                      500000000
                                    ) {
                                      window.sweetAlert(
                                        "",
                                        "Số tiền phải bé hoặc bằng 500tr",
                                        "warning"
                                      );
                                    } else if (
                                      newWallets[0] &&
                                      newWallets[0].balance <
                                        dataBetRecord.betRecordAmountIn
                                    ) {
                                      window.sweetAlert(
                                        "",
                                        "Số tiền vượt quá tài khoản hiện có",
                                        "warning"
                                      );
                                    } else {
                                      window.sweetAlert(
                                        {
                                          title: "Xác nhận giao dịch",
                                          html: true,
                                          customClass: "sweetCustorm",
                                          text: `
                                      <table class="table__bet">
                                        <tr>
                                          <td>Tên sản phẩm</td>
                                          <td>${typeMoney}/USD</th>                                     
                                        </tr>
                                        <tr>
                                          <td>Loại đầu tư</td>
                                          <td>${
                                            handleRenderTextType(
                                              dataBetRecord.betRecordType
                                            ).text
                                          }</td>                                  
                                        </tr>
                                        <tr>
                                          <td>Hoàn lại vốn đầu tư</td>
                                          <td>90%</td>                                    
                                        </tr>
                                        <tr>
                                          <td>Giá sản phẩm</td>
                                          <td>${
                                            typeMoney === BTC
                                              ? btcPrice
                                                ? btcPrice
                                                : "----"
                                              : ethPrice
                                              ? ethPrice
                                              : "----"
                                          } </td>                                    
                                        </tr>
                                        <tr>
                                        <td>Số tiền giao dịch</td>
                                        <td>${number_to_price(
                                          dataBetRecord.betRecordAmountIn
                                        )}</td>                                    
                                        </tr>
                                      </table>
                                      `,
                                          icon: "warning",
                                          showCancelButton: true,
                                          confirmButtonColor: "#00614A",
                                          confirmButtonText:
                                            "Xác nhận gửi lệnh",
                                          cancelButtonText: "Hủy yêu cầu",
                                          closeOnConfirm: true,
                                          closeOnCancel: true,
                                        },
                                        (isOke) => {
                                          if (isOke) {
                                            handleBetRecords(dataBetRecord);
                                          }
                                        }
                                      );
                                    }
                                  }
                                }}
                                type="submit"
                                className="btn-buy"
                              >
                                Xác nhận giao dịch
                              </button>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <RightMenu
          betRecordsListLive={betRecordsListLive}
          handleRenderTextType={handleRenderTextType}
          candlestickPayload={candlestickPayload}
          typeMoney={typeMoney}
          betRecordsListResult={betRecordsListResult}
        />
      </div>
      <div className="footer-group-button">
        <div id="navbarSupportedContent">
          <ul className="nav-btnGroup">
            <li>
              <button
                onClick={() => {
                  window.lightBox("#lightBoxRule");
                }}
              >
                Quy tắc
              </button>
            </li>
            <li>
              <button
                id="openhistoryrecord"
                onClick={() => {
                  setIsModalVisible(true);
                  setType("history");
                }}
              >
                Lịch sử
              </button>
            </li>
            <li>
              <button
                id="openbettingrecord"
                onClick={() => {
                  setIsModalVisible(true);
                  setType("bet");
                }}
              >
                Hoạt động
              </button>
            </li>
          </ul>
        </div>
      </div>
      <Modal
        className="modal--userHistory"
        footer={null}
        visible={isModalVisible && type === "history"}
        onCancel={() => {
          setIsModalVisible(false);
          setType("");
        }}
      >
        <div className="chart">
          <div style={{ minHeight: "400px" }} className="user-list ">
            <div className="record-half">
              <div className="frame-title">
                <img src="./assets/images/traffic.gif" /> Giao dịch của tôi
              </div>
              {betRecordsList &&
                betRecordsList.map((item) => (
                  <div className="side-log-item">
                    <div className="list-bet">
                      <div className="list-bet-time">
                        <span className="bet-time">
                          {moment(item.createdAt).format("HH:mm:ss")}
                        </span>
                      </div>
                      <div className="list-bet-stat">
                        <div className="list-bet-type">
                          <div className="bet-type">{item.betRecordUnit}</div>
                        </div>
                        <div className="list-bet-updown">
                          <div
                            className={`bet-number ${
                              handleRenderTextType(item.betRecordType).type
                            }`}
                          >
                            {handleRenderTextType(item.betRecordType).text}
                          </div>
                        </div>
                        <div className="list-bet-price">
                          <div className="bet-price" style={{ width: "unset" }}>
                            {number_to_price(item.betRecordAmountIn)}
                          </div>
                        </div>
                        <div
                          className={
                            item.betRecordResult !== "win"
                              ? "result_down"
                              : "result_up"
                          }
                        >
                          {item.betRecordResult === null
                            ? ""
                            : item.betRecordResult !== "win"
                            ? "Thua"
                            : "Thắng"}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        className="modal--userHistory"
        footer={null}
        visible={isModalVisible && type === "bet"}
        onCancel={() => {
          setIsModalVisible(false);
          setType("");
        }}
      >
        <div className="chart">
          <div style={{ minHeight: "400px" }} className="user-list ">
            <div className="record-half2">
              <div className="frame-title" style={{ marginTop: "20px" }}>
                <img src="./assets/images/traffic.gif" /> Giao dịch trực tiếp.
              </div>
              {betRecordsListLive &&
                betRecordsListLive.map((item) => (
                  <div className="side-log-item">
                    <div className="list-bet">
                      <div className="list-bet-time">
                        <span className="bet-time">
                          {moment(item.createdAt).format("HH:mm:ss")}
                        </span>
                      </div>
                      <div className="list-bet-stat">
                        <div className="list-bet-type">
                          <div className="bet-type">{item.betRecordUnit}</div>
                        </div>
                        <div className="list-bet-updown">
                          <div
                            className={`bet-number ${
                              handleRenderTextType(item.betRecordType).type
                            }`}
                          >
                            {handleRenderTextType(item.betRecordType).text}
                          </div>
                        </div>
                        <div className="list-bet-price">
                          <div className="bet-price">
                            {number_to_price(item.betRecordAmountIn)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
export default Chart;
