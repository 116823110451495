import React from "react";

const Logo = () => {
  return (
    <a href="/" style={{ position: "absolute", top: 10, left: 10 }}>
      <img
        alt="img"
        src="/assets/images/logo-full.png"
        height={80}
        className="enter-logo"
        width="auto"
      />
    </a>
  );
};

export default Logo;
