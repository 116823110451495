import { number_to_price } from "helper/common";
import moment from "moment";
import React, { useMemo, useState } from "react";
import "./TradingMarket.scss";

export const TradingMarket = ({
  betRecordsListLive,
  handleRenderTextType,
  forceGrid,
}) => {
  const [grid, setGrid] = useState(forceGrid || 1);

  const dataRender = (() => {
    if (!betRecordsListLive) return [[], null];

    const red = [];
    const green = [];

    if (forceGrid) {
      return [betRecordsListLive.reverse(), null];
    }

    for (let i = 0; i < betRecordsListLive.length; i++) {
      if (betRecordsListLive[i].betRecordType === "BetUp") {
        green.push(betRecordsListLive[i]);
        continue;
      }
      red.push(betRecordsListLive[i]);
    }

    if (grid === 1) {
      return [red.reverse(), green.reverse()];
    }

    if (grid === 2) {
      return [green.reverse(), null];
    }

    return [red.reverse(), null];
  })();

  const render1 = useMemo(() => {
    return (
      dataRender[0] &&
      dataRender[0].map((item) => (
        <div
          // key={`tradingmarket-${item.betRecordUnit}-${item.betRecordAmountIn}-${
          //   item.updatedAt || item.createdAt
          // }`}
          className="price-list-item d-flex"
        >
          <span
            className={`${item.betRecordType === "BetDown" ? "red" : "green"}`}
          >
            {moment(item.updatedAt || item.createdAt).format("HH:mm:ss")}
          </span>
          <span
            className={`${item.betRecordType === "BetDown" ? "red" : "green"}`}
          >
            {item.betRecordUnit}
          </span>
          <span
            className={`${item.betRecordType === "BetDown" ? "red" : "green"}`}
          >
            {number_to_price(item.betRecordAmountIn)}
          </span>
        </div>
      ))
    );
  }, [dataRender[0], grid, betRecordsListLive]);

  const render2 = useMemo(() => {
    return (
      dataRender[1] && (
        <div>
          <div className="top-description d-flex">
            <span>Thời gian</span>
            <span>Cặp</span>
            <span>Tổng</span>
          </div>
          <div className="price-list">
            {dataRender[1].map((item) => (
              <div
                // key={`tradingmarket2-${item.betRecordUnit}-${
                //   item.betRecordAmountIn
                // }-${item.updatedAt || item.createdAt}`}
                className="price-list-item d-flex"
              >
                <span
                  className={`${
                    item.betRecordType === "BetDown" ? "red" : "green"
                  }`}
                >
                  {moment(item.updatedAt || item.createdAt).format("HH:mm:ss")}
                </span>
                <span
                  className={`${
                    item.betRecordType === "BetDown" ? "red" : "green"
                  }`}
                >
                  {item.betRecordUnit}
                </span>
                <span
                  className={`${
                    item.betRecordType === "BetDown" ? "red" : "green"
                  }`}
                >
                  {number_to_price(item.betRecordAmountIn)}
                </span>
              </div>
            ))}
          </div>
        </div>
      )
    );
  }, [dataRender[1], grid]);

  return (
    <div className="left-list-container">
      <div className="body">
        <div className="top">
          {!forceGrid && (
            <div className="top-icon">
              <div
                onClick={() => setGrid(1)}
                className={`icon ${grid === 1 && "active"}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  class="css-3kwgah"
                >
                  <path d="M4 4h7v7H4V4z" fill="#F6465D"></path>
                  <path d="M4 13h7v7H4v-7z" fill="#0ECB81"></path>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
              <div
                onClick={() => setGrid(2)}
                className={`icon ${grid === 2 && "active"}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  class="css-3kwgah"
                >
                  <path d="M4 4h7v16H4V4z" fill="#0ECB81"></path>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
              <div
                onClick={() => setGrid(3)}
                className={`icon ${grid === 3 && "active"}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  class="css-3kwgah"
                >
                  <path d="M4 4h7v16H4V4z" fill="#F6465D"></path>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
            </div>
          )}

          <div className="price-container">
            <div>
              <div className="top-description d-flex">
                <span>Thời gian</span>
                <span>Cặp</span>
                <span>Tổng</span>
              </div>
              <div className={`price-list ${grid !== 1 && "x2"}`}>
                {render1}
              </div>
            </div>

            {render2}
          </div>
        </div>
      </div>
    </div>
  );
};
